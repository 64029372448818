<template>
  <div class="mobile-search-input">
    <div class="rounded-pill overflow-hidden ma-4">
      <v-text-field
        v-model="queryModel"
        :placeholder="placeholder"
        class="flat-text-field"
        variant="filled"
        density="compact"
        prepend-inner-icon="search"
        hide-details
      />
    </div>
    <v-tabs v-model="presetModel" density="compact" mandatory>
      <v-tab
        v-for="preset in categories"
        :key="preset.value"
        :value="preset.value"
        :ripple="false"
      >
        {{ preset.text }}
      </v-tab>
    </v-tabs>
    <v-divider />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { SearchCategory, getPlaceholder, searchCategories } from "./lib";

export default defineComponent({
  name: "MobileSearchInput",
  props: {
    query: { type: String },
    preset: {
      type: String as PropType<SearchCategory>,
      default: SearchCategory.PRODUCTS,
    },
  },
  setup(props, context) {
    const propRefs = toRefs(props);

    const queryModel = computed({
      set(value: string | undefined) {
        context.emit("update:query", value);
      },
      get(): string | undefined {
        return propRefs.query.value;
      },
    });

    const presetModel = computed({
      set(value: SearchCategory) {
        context.emit("update:preset", value);
      },
      get(): SearchCategory {
        return propRefs.preset.value;
      },
    });

    const placeholder = computed(() => getPlaceholder(presetModel.value));

    return {
      queryModel,
      presetModel,
      placeholder,
    };
  },
  data() {
    return {
      categories: searchCategories,
    };
  },
});
</script>

<style lang="scss">
.mobile-search-input {
  .v-btn__content {
    text-transform: none !important;
  }
  .v-tab__slider {
    border-radius: 3px 3px 0 0 !important;
  }
}
</style>
