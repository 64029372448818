<template>
  <v-dialog
    v-model="open"
    :fullscreen="fullscreen"
    :scrim="!fullscreen"
    style="z-index: 2403"
    close-on-back
    scrollable
  >
    <v-card class="search-content" height="100%">
      <mobile-search-input
        class="search-content-item-1"
        v-model:query="query"
        v-model:preset="preset"
      />
      <search-content
        class="search-content-item-2"
        :query="query"
        :hits="hits"
        :preset="preset"
        :recent-searches="history"
        :loading="loading"
        :search="search"
      />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MobileSearchInput from "./MobileSearchInput.vue";
import SearchContent from "./SearchContent.vue";

export default defineComponent({
  components: { MobileSearchInput, SearchContent },
  name: "MobileSearchDialog",
  props: {
    modelValue: { type: Boolean },
    fullscreen: { type: Boolean },
  },
  setup() {
    const search = useSearch();
    return search;
  },
  computed: {
    open: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.search-content {
  display: flex;
  flex-flow: column;
  height: 100%;
  .search-content-item-1 {
    flex: 0 1 auto;
  }
  .search-content-item-2 {
    flex: 1 1 auto;
    max-height: 100%;
    overflow-y: auto;
  }
}
</style>
